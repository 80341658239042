import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Dialog, DialogBody } from '@blueprintjs/core';
import { useGetItemsQuery, useUpdatePropertyMutation } from '../../store/docApi';
import { appstyles } from '../../styles/appstyles';
import ColorChart from '../Widgets/ColorChart';
import { ColorChartThumbnail } from '../Widgets/ColorChartThumbnail';
//import { DoubleKnitChart } from '../components/DoubleKnitChart';
import { maxnum } from '../../utils';

export function ProjectMotifs({ item, motifIndex, isEdit }) {
    const { data: motifs, isLoading: isLoadingMotifs } = useGetItemsQuery({ label: "motif" });

    const [updateProperty] = useUpdatePropertyMutation();

    const [selectedSquareIndex, setSelectedSquareIndex] = useState(null);
    const [colorModalVisible, setColorModalVisible] = useState(false);

    const [steps, setSteps] = useState([]);

    const [totalRows, setTotalRows] = useState(0);
    const [cols, setCols] = useState('24');

    const [showAddModal, setShowAddModal] = useState(false);
    const [showStepModal, setShowStepModal] = useState(false);
    //const [selectedMotif, setSelectedMotif] = useState(null);
    const [horizontal, setHorizontal] = useState(false);
    //const [highlightRow, setHighlightRow] = useState(5);

    const [editStepIndex, setEditStepIndex] = useState(-1);
    const [editSubstepIndex, setEditSubstepIndex] = useState(-1);
    const [itemstep, setItemStep] = useState(null);

    useEffect(() => {
        if (item && item.steps && item.steps.length >= motifIndex) {
            setItemStep(item.steps[motifIndex]);
            let motifSteps = item.steps[motifIndex].steps || [];
            setSteps(motifSteps);
            let totalr = 0;
            for (let i = 0; i < motifSteps.length; i++) {
                if (motifSteps[i].data) totalr += motifSteps[i].data.length
            }
            setTotalRows(totalr)
        }
        //if (item && item.currentRow) setHighlightRow(item.currentRow);
    }, [item, setTotalRows, motifIndex]);

    async function saveSteps(newsteps) {
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: `steps/${motifIndex}/steps`, value: newsteps });
    }

    const addStep = (i, horizontal) => {
        if (i === undefined) setEditStepIndex(-1);
        setHorizontal(horizontal);
        setShowAddModal(true);
    };

    const deleteStep = (index) => {
        if (index < 0 || index >= steps.length)
            return;
        const newsteps = [...steps];
        newsteps.splice(index, 1);
        setSteps(newsteps);
        setEditStepIndex(-1);
        saveSteps(newsteps);
    }

    const handleAddStep = (motif) => {
        const maxcolor = maxnum(motif.data);
        const newcolors = [];
        for (let i = 0; i <= maxcolor; i++) {
            if (item.colors && item.colors.length > i) newcolors.push(item.colors[i]);
            else if (motif.colors && motif.colors.length > i) newcolors.push(motif.colors[i]);
            else newcolors.push('white');
        }
        const newstep = { name: motif.name, data: motif.data, colors: newcolors };
        const newsteps = JSON.parse(JSON.stringify(steps));
        if (editStepIndex >= 0) {
            if (!horizontal)
                newsteps.splice(editStepIndex, 0, newstep);
            else {
                let tempstep = JSON.parse(JSON.stringify(newsteps[editStepIndex]));
                if (!newsteps[editStepIndex].steps) {
                    newsteps[editStepIndex] = { steps: [] };
                    newsteps[editStepIndex].steps.push(tempstep);
                }
                newsteps[editStepIndex].steps.push(newstep);
            }
        }
        else {
            newsteps.push(newstep);
        }
        setSteps(newsteps);
        setShowAddModal(false);
        saveSteps(newsteps);
    };

    function onStepPress(i, j) {
        setEditStepIndex(i);
        setEditSubstepIndex(j);
        if (!isEdit) setShowStepModal(true);
    }

    const changeColor = (color) => {
        console.log("changeColor", editStepIndex, editSubstepIndex)
        if (selectedSquareIndex !== null && editStepIndex >= 0) {
            const newsteps = JSON.parse(JSON.stringify(steps));
            const newcolors = editSubstepIndex >= 0 ?
                newsteps[editStepIndex].steps[editSubstepIndex].colors :
                [...newsteps[editStepIndex].colors];
            newcolors[selectedSquareIndex] = color;
            console.log(newsteps)
            if (editSubstepIndex >= 0) newsteps[editStepIndex].steps[editSubstepIndex].colors = [...newcolors];
            else newsteps[editStepIndex].colors = [...newcolors];
            setSteps(newsteps);
            saveSteps(newsteps);
        }
        setColorModalVisible(false);
    };

    function onCloseEditStep() {
        setEditStepIndex(-1);
        setEditSubstepIndex(-1);
        setShowStepModal(false);
    }

    const EditStepSection = () => {
        const i = editStepIndex;
        const j = editSubstepIndex;
        let step = {};
        if (j >= 0) step = steps[i].steps[j];
        else step = steps[i];
        return <div style={styles.edit}>
            <div>
                <div>{step.name}</div>
                <Button onClick={() => onCloseEditStep()}>Close</Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, padding: 8 }}>
                {step.colors && step.colors.map((color, index) => (
                    <div key={index} onClick={() => { setSelectedSquareIndex(index); setColorModalVisible(true); }}>
                        <div style={{ width: 40, height: 40, backgroundColor: color, borderWidth: 0.5, borderColor: 'grey', borderStyle: 'solid' }} />
                    </div>
                ))}
            </div>
            <Dialog isOpen={colorModalVisible} onClose={() => setColorModalVisible(false)} title="Choose Color">
                <DialogBody>
                    {item.colors && item.colors.map((col, k) => (
                        <Button key={k}
                            style={{ width: 80, height: 80, backgroundColor: col, borderWidth: 0.5, borderColor: 'grey', marginLeft: 16 }}
                            onClick={() => changeColor(col)}
                        />
                    ))}
                </DialogBody>
            </Dialog>
            <div>
                <Button onClick={() => addStep(i)} icon="arrow-down">Add below</Button>
                &nbsp;&nbsp;
                <Button onClick={() => addStep(i, true)} icon="arrow-right">Add right</Button>
                &nbsp;&nbsp;
                <Button onClick={() => deleteStep(i)} icon="trash">Delete</Button>
                &nbsp;&nbsp;
                <Button onClick={() => setShowStepModal(true)}>View</Button>
            </div>
        </div>
    }

    const MakeStepSection = () => {
        const i = editStepIndex;
        const j = editSubstepIndex;
        let step = {};
        let depth = `${i}`;
        if (j >= 0) {
            step = steps[i].steps[j];
            depth = `${i}_${j}`
        }
        else step = steps[i];
        return <div style={appstyles.content}>
            <p><b>{step.name}</b></p>
            <ColorChart item={step} project={item} depth={depth} />
        </div>
    }

    console.log("itemstep", itemstep, "item", item);
    return item && itemstep && <div>
        <div style={appstyles.section}>
            {isEdit &&
                <div style={appstyles.sectionHeader}>
                    <span style={appstyles.sectionTitle}>{itemstep.name}</span>
                    <Button onClick={() => addStep()}>Add motif</Button>
                </div>
            }
            {itemstep.note && <span style={appstyles.note}>{itemstep.note}</span>}
            <div>
                <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                    <span>Number of columns:</span>
                    <input type="number" max="200" style={appstyles.input} value={cols} onChange={e => setCols(e.target.value)} />
                </div>
                <span>Total rows: {totalRows}</span>
            </div>
            <div style={{ overflowX: 'auto' }}>
                <div style={styles.chartcontainer}>
                    {steps && steps.map((step, i) => (
                        step.steps ? <div key={i}>
                            <div style={styles.horizontalDiv}>
                                {step.steps.map((substep, j) => (
                                    <div key={`${i}_${j}`} style={{ borderWidth: 3, borderColor: editSubstepIndex === j ? 'yellow' : 'white' }}>
                                        <div onClick={() => onStepPress(i, j)}>
                                            <ColorChartThumbnail data={substep.data} size={2} colors={substep.colors} />
                                            {/*<ColorChart item={substep} size={2} colors={substep.colors} thumbnail={true} />*/}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={styles.horizontalDiv}>
                                {isEdit && editStepIndex === i && editSubstepIndex < step.steps.length &&
                                    <EditStepSection step={step} i={editStepIndex} j={editSubstepIndex} />}
                            </div>
                        </div> : <div key={i}>
                            <div style={styles.horizontalDiv}>
                                <div style={{ borderWidth: 2, borderColor: editStepIndex === i ? 'yellow' : 'white' }}>
                                    <div onClick={() => onStepPress(i, -1)}>
                                        <ColorChartThumbnail data={step.data} mincols={parseInt(cols)} size={2} colors={step.colors} />
                                        {/*<ColorChart thumbnail={true} item={step} mincols={parseInt(cols)} size={2} colors={step.colors} />*/}
                                    </div>
                                </div>
                            </div>
                            <div style={styles.horizontalDiv}>
                                {isEdit && editStepIndex === i && <EditStepSection step={step} i={i} />}
                            </div>
                        </div>

                    ))}
                </div>
            </div>
            {/*<ColorChartSvg steps={steps} width={60} size={2} />*/}
            <Dialog isOpen={showAddModal} onClose={() => setShowAddModal(false)} title="Choose a motif">
                <DialogBody>
                    {isLoadingMotifs && <div>Loading...</div>}
                    {motifs && motifs.map((motif, i) => (
                        <Button key={i} onClick={() => handleAddStep(motif)}
                            style={{ padding: 16, margin: 16 }}>
                            <div>
                                <ColorChartThumbnail data={motif.data} colors={motif.colors} mincols={24} />
                                <div style={{ marginTop: 8 }}>{motif.name}</div>
                            </div>
                        </Button>
                    ))}
                </DialogBody>
            </Dialog>
            <Dialog isOpen={showStepModal} onClose={() => setShowStepModal(false)}
                title="Motif Details">
                <DialogBody>
                    <MakeStepSection />
                </DialogBody>
            </Dialog>
        </div>
        {/*itemstep.doubleknit && steps && steps.length > 1 &&
            <DoubleKnitChart key={item._ts} data1={steps[0].data} data2={steps[1].data} depth={motifIndex} project={item} />
        */}

    </div>
}

const styles = {
    horizontalDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    card: {
        margin: 10,
        padding: 10
    },
    description: {
        margin: 8
    },
    highlightCard: {
        margin: 10,
        padding: 10,
        backgroundColor: 'lightyellow'
    },
    chartcontainer: {
        display: 'flex',
        flex: 1,
        //alignItems: "center",
        //justifyContent: "center",
        margin: 8,
        flexDirection: 'column-reverse'
    },
    image: {
        width: 200,
        height: 200,
    },
    modalTitle: {
        margin: 20,
    },
    option: {
        fontSize: 18,
        marginBottom: 10,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        //alignItems: 'center',
        gap: 20,
        borderRadius: 8,
        padding: 8
    },
    step: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        margin: 4,
        padding: 8,
        backgroundColor: 'white',
        borderRadius: 8
    },
    colorList: {
        padding: 20,
    },
    colorItem: {
        width: 80,
        height: 80,
        margin: 5,
        borderRadius: 5,
    },
    actionbutton: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8
    },
    edit: {
        borderWidth: 1,
        borderColor: 'grey',
        padding: 12,
        margin: 8,
        marginBottom: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 5
    }
}
