import { useParams } from 'react-router-dom';
import { useGetNodeQuery } from '../../store/graphApi';
import { useGetDashboardLinkQuery } from '../../store/stripeApi';
import Picture from '../Widgets/Picture';
import Links from '../Widgets/Links';
import Tags from '../Widgets/Tags';
import FileUpload from '../Widgets/FileUpload';
import StoreManage from './StoreManage';
import Products from './Products';
import { getDisplayValue } from '../../utils';

export default function StoreDetails() {
    let params = useParams();

    const { data: node } = useGetNodeQuery({ label: "store", pk: params.pk, id: params.id });
    const { data: dashboard } = useGetDashboardLinkQuery({ accountId: node?.stripeAccountId }, { skip: !node?.stripeAccountId });

    return node &&
        <div style={styles.content}>
            {node.name && <b>{node.name} </b>}
            <Picture picture={node.picture} pictures={node.pictures} />
            {node.description}
            {node.host && <div>Hosted by {node.host}</div>}
            {node.hashtags && <Tags tags={node.hashtags} />}
            {node.website && <Links links={[{"url": node.website}]} />}
            {node.address && <span>{node.address}</span>}
            {node.email && <span>{node.email}</span>}
            <hr />
            {Object.keys(node).map((prop, i) =>
                <div key={i}>
                  <b>{prop}</b> : {getDisplayValue(prop, node[prop])}
                </div>
            )}
            <hr />
            {dashboard && dashboard.url && <div><a href={dashboard.url} target="_blank" rel="noreferrer">Dashboard</a></div>}
            <hr />
            <FileUpload folder={node.pk} item={node} itemType="node" />
            <hr />
            <StoreManage node={node} />
            {node.stripeAccountId && <Products stripeAccountId={node.stripeAccountId} />}
            <div align='right' className="bp4-text-small"><br /><i>{node.id}</i></div>
        </div>
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column'
    }
}
