import { useNavigate } from "react-router-dom";
import { Card, Icon, Spinner, Tag } from "@blueprintjs/core";
import { useGetSubitemsQuery } from '../../store/docApi';
import { Colors } from '../../styles';
import { getCraftIcon, getMonthYear } from "../../utils";
import { appstyles } from "../../styles/appstyles";

export default function ProjectList({ username, craft, referrer }) {
  const { data, error, isLoading } = useGetSubitemsQuery({ label: 'project', pk: username }, { skip: !username })
  const navigate = useNavigate();

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  function show(p) {
    if (!craft) return true; // no filter set
    return p.craft && p.craft === craft;
  }

  function getStateColor(state) {
    switch (state) {
      case 'done':
        return Colors.ProgressDone;
      case 'started':
        return "indianred";
      case 'joined':
        return 'darkslateblue';
      default:
        return Colors.ProgressNotStarted;
    }
  }

  function getCraftIntent(state) {
    switch (state) {
      case 'done':
        return 'success';
      case 'started':
        return 'warning';
      case 'joined':
        return 'primary';
      default:
        return 'none';
    }
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner />) : <div>
      {items.map((project, i) => (show(project) &&
        <Card key={i} style={appstyles.card} interactive={true}
          onClick={() => navigate(referrer === 'projects' ? `/project/${project.pk}/${project.id}` : `/@${project.pk}/${project.id}`)}>
          {project.picture ? <img style={appstyles.cardImg} src={project.picture} alt={project.name} /> :
            project.pictures && project.pictures.length > 0 ? <img style={appstyles.cardImg} src={project.pictures[0]} alt={project.name} /> :
              <div style={appstyles.cardNoimg}><Icon icon={getCraftIcon(project.craft)} size={60} /></div>}
          <div style={appstyles.cardContent}>
            <div style={appstyles.cardTitle}>{project.name}</div>
            <div>
              {project.craft && <Tag minimal round intent={getCraftIntent(project.state)}>{project.craft}</Tag>}
              <Icon icon={project.summary ? 'eye-on' : 'eye-off'} color={project.summary ? 'green' : 'lightgrey'} />
              <span style={{ ...styles.state, color: getStateColor(project.state) }}> {project.state}</span>
            </div>
            {project.description && <div style={appstyles.cardDescription}>{project.description}</div>}
            {project.endDate && <div>{getMonthYear(new Date(project.endDate))}</div>}
          </div>
        </Card>
      ))}
    </div>
}

const styles = {
  state: {
    fontSize: 8,
    fontWeight: 'bold'
  }
}