import { Card } from '@blueprintjs/core';
import { useGetItemQuery, useGetRelatedQuery } from '../../store/docApi';
import Picture from '../Widgets/Picture';
import Links from '../Widgets/Links';
import Palette from '../Parts/Palette';

export default function Overview({ patternPk, patternId, lang }) {
  const { data: item } = useGetItemQuery({ container: "design", label: "pattern", pk: patternPk, id: patternId });
  const { data: subitems } = useGetRelatedQuery({ name: "isPartOf", value: `pattern/${patternPk}/${patternId}`, container: "design", pk: patternPk })

  let palette = subitems ? subitems.find(s => s.label === "palette") : undefined;

  function LANG(prop) {
    return item[`${prop}_${lang}`] ? item[`${prop}_${lang}`] : item[prop];
  }

  return item && (
    <Card style={styles.section}>
      <Picture picture={item.picture} pictures={item.pictures} />
      {item.description && <p>{LANG('description')}</p>}
      {item.note && <p><i>{item.note}</i></p>}
      <br />
      {item.yarn &&
        <div>
          <b>Yarn</b>
          <ul>
            {item.yarn.map((yarn, i) =>
              <li key={i}>{yarn.name} <i>{yarn.note}</i></li>
            )}
          </ul>
          {item.yardage && <p>{LANG('yardage')}</p>}
        </div>}
      {palette && <Palette subitem={palette} />}
      <Links style={styles.section} links={item.links} />
    </Card>
  )
}

const styles = {
  card: {
    marginTop: 20
  }
}