import { useParams } from 'react-router-dom';
import { useGetItemQuery, useGetConnectedAllQuery } from '../../store/docApi';
import Picture from '../Widgets/Picture';
import MotifCard from '../Motif/MotifCard';

export default function CollectionDetails() {
  let params = useParams();
  const { data: item, error, isLoading } = useGetItemQuery({ label: "collection", pk: params.pk, id: params.id });
  const { data: motifs, isLoading: motifsLoading } = useGetConnectedAllQuery({
    _id: `collection/${params.pk}/${params.id}`, container: "design", pk: params.pk });

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div>
        {item && <h3>{item.name}</h3>}
        <Picture picture={item.picture} pictures={item.pictures} />
        {item.description && <p>{item.description}</p>}
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '16px' }}>
          {motifsLoading ? <div>Loading...</div> :
            motifs && motifs.map((motif, i) =>
              <MotifCard key={i} motif={motif} />
            )}
        </div>
      </div>
}
