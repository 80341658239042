import React, { useEffect, useState } from 'react';
import { useUpdatePropertyMutation } from '../../store/docApi';
import Stepper from './Stepper';
import { useGetProjectQuery } from '../../store/appApi';
import ColorChart from '../Widgets/ColorChart';
import { Button } from '@blueprintjs/core';

export function ProjectWizard({ pk, id, stepIndex }) {
    const [currentSize, setCurrentSize] = useState("1");
    const { data: item } = useGetProjectQuery({ pk: pk, id: id });
    const [updateProperty] = useUpdatePropertyMutation();
    const [currentStep, setCurrentStep] = useState();
    const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex);
    const [progress, setProgress] = useState();
    const [pattern, setPattern] = useState();

    useEffect(() => {
        if (item) {
            setProgress(item.progress);
            setCurrentSize(item.currentSize);
            setPattern(item.pattern);
        }
    }, [item]);

    useEffect(() => {
        if (item && item.pattern) {
            let _pattern = item.pattern;
            console.log("item", item);
            let _currentStep = _pattern && _pattern.steps && _pattern.steps.length > 0 ? { ..._pattern.steps[currentStepIndex] } : -1;
            console.log("currentStep", _currentStep, _currentStep.data);
            _currentStep.stepIndex = currentStepIndex;
            if (_currentStep) {
                if (_currentStep.part_id && _pattern.parts) {
                    const part = _pattern.parts.find(p => p.id === _currentStep.part_id);
                    //console.log(_pattern.parts);
                    //console.log("MAIN part_id", _currentStep.part_id, "part: ", part && part.id, "index: ", _currentStep.stepIndex, currentStepIndex);
                    if (part) {
                        _currentStep = { ..._currentStep, ...part };
                    }
                }
            }
            setCurrentStep(_currentStep);
            window.scrollTo(0, 0);
        }
    }, [item, currentStepIndex]);

    function getProgress(id) {
        if (progress)
            return progress[id];
        else
            return "";
    }

    async function selectStep(stepid, index) {
        updateProgress(`${stepid}_step`, index);
    }

    async function selectOption(stepid, index) {
        updateProgress(`${stepid}_option`, index);
    };

    const handleNextStep = () => {
        setCurrentStepIndex((prev) => prev + 1);
    };

    const handlePrevStep = () => {
        setCurrentStepIndex((prev) => prev - 1);
    };

    async function updateProgress(name, value) {
        let newprogress = { ...progress };
        newprogress[name] = value;
        setProgress(newprogress);
        let updateParams = { label: "project", pk: item.pk, id: item.id, name: `progress/${name}`, value: value };
        await updateProperty(updateParams);
    }

    const NextPrevButtons = () => {
        return <div style={styles.buttonContainer}>
            {currentStepIndex === 0 ? <div /> :
                <Button style={{marginRight: 32}} icon='arrow-left' onClick={handlePrevStep}>Back</Button>}
            {currentStepIndex === pattern.steps.length - 1 ? <div /> :
                <Button icon='arrow-right' onClick={handleNextStep} >Next</Button>}
        </div>
    }

    const Steps = ({ steps, depth }) => {
        return steps && steps.map((step, i) => {
            let stepid = depth ? depth : '';
            let optionStepid = depth ? `${depth}_${i}` : i;
            let stepprogress = getProgress(`${stepid}_step`);
            let selectedOption;
            let optionindex = getProgress(`${optionStepid}_option`) || 0;
            if (step.part_id && pattern.parts) {
                const part = pattern.parts.find(p => p.id === step.part_id);
                //console.log("STEPS part_id", step.part_id, "part: ", part && part.id);
                if (part) {
                    step = { ...step, ...part };
                }
            }
            if (step.options) {
                if (optionindex >= 0 && optionindex < step.options.length)
                    selectedOption = step.options[optionindex];
            }
            if (step.sizes && !step.sizes.includes(currentSize)) return <></>;

            return (
                <div key={i}>
                    {step.title && <p style={styles.title}>{step.title}</p>}
                    {step.note && <p style={styles.note}>{step.note}</p>}

                    <div onClick={() => selectStep(stepid, i)}>
                        <div>
                            <div style={stepprogress === i ? styles.selectedSubStep : styles.subStep}>
                                {step.name && <p style={styles.stepName}>{step.name} </p>}
                                {step.verbal && <p style={styles.verbal}>{step.verbal}</p>}
                                {step.text && step.params && <p>{formatString(step.text, step.params)}</p>}
                            </div>
                        </div>
                    </div>

                    {step.options &&
                        <div style={styles.optionContainer}>
                            {step.options.map((option, j) => (
                                <Button key={j} onClick={() => selectOption(optionStepid, j)}
                                    intent={optionindex === j ? 'primary' : 'none'} >
                                    {option.name}</Button>
                            ))}
                        </div>}
                    {selectedOption && (
                        <div style={styles.optionDetails}>
                            {selectedOption.name && <p style={styles.name}>{selectedOption.name}</p>}
                            {selectedOption.verbal &&
                                <Button onClick={() => selectStep(stepid, i)}
                                    intent={stepprogress === i ? 'primary' : 'none'}>
                                    {selectedOption.verbal}
                                </Button>}
                            {selectedOption.steps &&
                                <Steps steps={selectedOption.steps} depth={`${depth}_${i}`} />
                            }
                        </div>
                    )}
                    {step.steps && <Steps steps={step.steps} depth={`${depth}_${i}`} />}
                    {/*step.colorchart && <ProjectMotifs motifIndex={currentStepIndex} item={step} />*/}
                    {step.colorchart && <ColorChart key={`${depth}_${i}`} item={step} depth={`${depth}_${i}`} project={item} viewonly={true} />}
                    {step.endnote && <p style={styles.note}>{step.endnote}</p>}
                </div>
            )
        })
    }

    const formatString = (str, params) => {
        return str.replace(/\${(.*?)}/g, (match, p1) => params[p1][currentSize] || '');
    };

    return pattern && pattern.steps && item && currentStep && (
        <div style={styles.screen} >
            <div flexdirection="row">
                <div style={{ marginLeft: 4 }}>
                    <div flexdirection="row">
                        <p>{currentSize}</p>
                        <Stepper steps={pattern.steps} currentStep={currentStepIndex} onStepPress={setCurrentStepIndex} />
                    </div>
                    <div style={styles.container}>
                        <p style={styles.header}>{currentStep.name}</p>
                        {currentStep.verbal && <p style={styles.verbal}>{currentStep.verbal}</p>}
                        {/*currentStep.colorchart && <ProjectMotifs motifIndex={currentStepIndex} item={item} />*/}
                        {currentStep.steps && <Steps steps={currentStep.steps} depth={currentStepIndex} />}
                        {currentStep.colorchart && <ColorChart key={currentStep.stepIndex} item={currentStep} depth={currentStepIndex} project={item} viewonly={true} />
                        }
                        <NextPrevButtons />
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    sidebar: {
        //width: Dimensions.get('window').width * 0.25,
        backgroundColor: '#f0f0f0',
    },
    screen: {
        flex: 1,
        backgroundColor: 'white',
        padding: 8
    },
    container: {
        justifyContent: 'center',
        marginBottom: 30,
        //marginLeft: Dimensions.get('window').width * 0.6,
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        margin: 8
    },
    title: {
        fontWeight: 'bold',
        marginBottom: 8
    },
    name: {
        fontWeight: 'bold',
        marginBottom: 8
    },
    verbal: {
        fontSize: 16,
        marginBottom: 8,
    },
    note: {
        fontStyle: 'italic'
    },
    optionContainer: {
        flexDirection: 'row',
        flexWrap: "wrap",
        justifyContent: 'space-between',
        marginBottom: 8,
        gap: 2
    },
    optionButton: {
        flex: 1,
        padding: 10,
        marginHorizontal: 5,
        backgroundColor: '#a0d6b4',
        alignItems: 'center',
        borderRadius: 5,
        minWidth: 100
    },
    selectedOptionButton: {
        backgroundColor: 'teal',
    },
    optionButtonText: {
        color: 'white',
        textAlign: 'center'
    },
    optionDetails: {
        marginTop: 20,
    },
    optionInstructions: {
        fontSize: 16,
        color: 'darkblue',
    },
    subStep: {
        fontSize: 16,
        marginVertical: 5,
        borderTopWidth: 1,
        padding: 8,
        borderColor: 'lightgrey'
    },
    selectedSubStep: {
        backgroundColor: 'lightyellow',
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 20,
        display: 'flex'
    },
    progressContainer: {
        marginTop: 30,
    },
    progressHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    progressText: {
        fontSize: 16,
    },
    stepName: {
        fontWeight: 'bold'
    },
};
