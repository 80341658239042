import { useNavigate } from "react-router-dom";
import { Card } from "@blueprintjs/core";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <center>
      <div style={styles.grid} >
      <Card interactive style={styles.card} onClick={()=>navigate('projects')}>
          <div style={styles.title}>Projects</div>
        </Card>
        <Card interactive style={styles.card} onClick={()=>navigate('library')}>
          <div style={styles.title}>Library</div>
        </Card>
        <Card interactive style={styles.card} onClick={()=>navigate('designs')}>
          <div style={styles.title}>Patterns</div>
        </Card>
        <Card interactive style={styles.card} onClick={()=>navigate('explore')}>
          <div style={styles.title}>Events</div>
        </Card>
      </div>
    </center>
  );
}

const styles = {
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: 50,
    maxWidth: 300,
    },
  card: {
    margin: 10,
    height: 110,
    width: 110,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 160,
    borderRadius: 20

  },
  title: {
    textTransform: 'uppercase',
    fontSize: 18
  }
}

