export const appstyles = {
    title: {
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontSize: 22
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        fontSize: 9,
        backgroundColor: '#f6f7f9',
        padding: 4
    },
    tileGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    tile: {
        borderRadius: 5,
        width: 128,
        height: 200,
        margin: 8,
        padding: 4
    },
    tileImg: {
        height: 120,
        width: 120,
        objectFit: 'cover',
        borderRadius: 5
    },
    tileNoimg: {
        padding: 16,
        backgroundColor: 'white',
        color: 'lightgrey',
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        height: 120,
        width: 120,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    tileLink: {
        fontSize: 'small',
        color: 'grey',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        textAlign: 'center',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical'
    },
    tileTitle: {
        //fontSize: 'medium',
        //fontWeight: 'bold',
        marginTop: 8,
        marginBottom: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        textAlign: 'center',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    card: {
        borderRadius: 5,
        height: 100,
        margin: 8,
        padding: 4,
        display: 'flex',
    },
    cardImg: {
        height: 92,
        width: 92,
        objectFit: 'cover',
        borderRadius: 5
    },
    cardNoimg: {
        padding: 16,
        backgroundColor: 'white',
        color: 'lightgrey',
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        height: 92,
        width: 92,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    cardTitle: {
        paddingTop: 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    cardContent: {
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    cardDescription: {
        fontSize: 'small',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    }
};