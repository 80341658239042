import { Tab, Tabs } from "@blueprintjs/core";
import Collections from "../Collection/Collections";
import Motifs from "../Motif/Motifs";
import Patterns from "../Pattern/Patterns";

export default function DesignPage() {
  return (
    <Tabs id="DesignPage" animate={true} large={true} vertical>
      <Tab title="Patterns" id="patterns" panel={<Patterns />} />
      <Tab title="Motifs" id="motifs" panel={<Motifs />} />
      <Tab title="Collections" id="collections" panel={<Collections />} />
    </Tabs>
  );
}


