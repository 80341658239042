import React from 'react';

const Stepper = ({ steps, currentStep, onStepPress }) => {
  return (
    <div style={styles.stepperContainer}>
      {steps.map((step, index) => (
        <div key={index} style={currentStep === index ? styles.activeStep : styles.step}
          onClick={() => onStepPress(index)}>
          <span style={currentStep === index ? styles.activeStepText : styles.stepText}>
            .
          </span>
        </div>
      ))}
    </div>
  );
};

const styles = {
  stepperContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //paddingVertical: 10,
    backgroundColor: '#f0f0f0',
    display: 'flex'
  },
  step: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    cursor: 'pointer',
  },
  activeStep: {
    padding: 4,
    backgroundColor: 'teal',
  },
  stepText: {
    fontSize: 10,
    color: '#000',
  },
  activeStepText: {
    fontSize: 10,
    color: '#fff',
    fontWeight: 'bold',
  },
};

export default Stepper;
