import { appstyles } from '../styles/appstyles';
import NavMenu from './NavMenu';

export default function Layout({ children }) {
  return (
    <div style={styles.page}>
      <div style={styles.navMenu}>
        <NavMenu />
      </div>
      <div style={styles.main}>
        {children}
      </div>
      <div style={appstyles.footer}>~ STITCHSOME LLC ~</div>
    </div>
  );
}

const styles = {
  page: {
    minWidth: 300,
    display: "flex",
    flexDirection: "column"
  },
  main: {
    padding: 8,
    overflowX: 'auto',
    overflowY: 'auto',
    marginTop: 56, // navbar
    marginBottom: 16, // footer
  },
  navMenu: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#f8f9fa'
  }
}