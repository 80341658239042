import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';

export default function TableOfContents({ steps }) {
    const [activeStep, setActiveStep] = useState(null);
    const [isMobileOpen, setIsMobileOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSteps = (steps, level = 0) => {
        return steps.map((step, index) => (
            <div key={index} style={{ marginLeft: level * 10 }}>
                <Link
                    to={step.link}
                    style={activeStep === step ? styles.active : {}}
                    onClick={() => handleStepClick(step)}
                >
                    {step.name}
                </Link>
                {step.steps && renderSteps(step.steps, level + 1)}
            </div>
        ));
    };

    return (
        <div>
            <div>{windowSize.width} x {windowSize.height}</div>
            {isMobile &&
                <div>
                    <Button icon={isMobileOpen ? 'chevron-left' : 'chevron-right'} onClick={() => setIsMobileOpen(!isMobileOpen)}>
                    </Button>
                    {isMobileOpen && "  Contents"}
                </div>
            }
            
            {isMobileOpen &&
                <div style={styles.main}>
                    <div style={styles.stepList} className={isMobileOpen ? '' : 'contents-menu'}>
                        {renderSteps(steps)}
                    </div>
                </div>}
        </div>
    );
};

const styles = {
    main: {
        //position: 'relative',
        minWidth: 200,
        background: 'white',
        marginRight: 8,
        borderRight: '1px solid #ddd',
    },
    mobileToggle: {
        display: 'none',
        position: 'absolute',
        top: 10,
        right: 10,
    },
    stepList: {
        padding: 20,
    },
    '@media (max-width: 768px)': {
        mobileToggle: {
            display: 'block',
        },
        stepList: {
            display: 'none',
        }
    },
    active: {
        fontWeight: 'bold',
        color: '#007bff',
    },
};

