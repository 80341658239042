import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { useGetItemQuery } from '../../store/docApi';
import { usePackagePatternMutation } from '../../store/appApi';
import Instructions from './Instructions';
import Overview from './Overview';
import ProductCreate from '../Store/ProductCreate';
import { ProjectMotifs } from '../Widgets/ProjectMotifs';
import TableOfContents from './TableOfContents';

export default function Pattern({ patternPk, patternId, projectId, stepId }) {
  const { data: item, isLoading } = useGetItemQuery({ label: "pattern", pk: patternPk, id: patternId });
  const [packagePattern, result] = usePackagePatternMutation();

  const [showPublish, setShowPublish] = useState(false);
  const [selectedTab, setSelectedTab] = useState(projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      backgroundColor: '#007067',
      color: 'white'
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  function publish() {
    setShowPublish(true);
  }

  async function callPackagePattern() {
    await packagePattern({ label: "pattern", pk: patternPk, id: patternId })
    console.log(result);
  }

  return isLoading ? <Spinner /> :
    item && <div style={{ display: 'flex', flexDirection: 'row' }}>
      <TableOfContents steps={item.steps} />
      <div><div style={styles.panel}>
        <div>
          <h2>{item.name}</h2>
          {/*node && <i> ({node.name} {node.stripeProductId && node.stripeProductId})</i>*/}
          {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
        </div>
        <div>
          {item.lang && item.lang.map((lang, i) =>
            <Button small key={i} style={tabStyle(lang === currentLang)}
              onClick={() => setCurrentLang(lang)}>{lang}</Button>
          )}
        </div>
      </div>
        <Button large style={tabStyle(selectedTab === "overview")}
          onClick={() => setSelectedTab("overview")}>Overview</Button>
        &nbsp;&nbsp;
        <Button large style={tabStyle(selectedTab === "instructions")}
          onClick={() => setSelectedTab("instructions")}>Instructions</Button>
        &nbsp;&nbsp;
        <Button large minimal onClick={() => edit()}>Edit</Button>
        <Button large minimal onClick={() => publish()}>Publish</Button>
        <Button large minimal onClick={() => callPackagePattern()}>Package</Button>
        <br />
        {showPublish && <ProductCreate item={item} />}
        <br />
        {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
        {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} />}
        <ProjectMotifs item={item} motifIndex={2} isEdit={true} />
        <div align='right' className="bp4-text-small"><br /><i>{item.id}</i></div>
      </div></div>
}

const styles = {
  panel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  img: {
    height: 100,
    width: 100,
    borderRadius: 5
  }
};
